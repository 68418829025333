body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.playerclass:nth-child(odd){
  background-color: rgba(27, 32, 42, 0.46);
}
.playerclass:nth-child(even){
  background-color: rgba(41, 50, 61, 0.24);
}
.playerclass{
  padding: 2px;
  padding-left: 5px;
}
::-webkit-scrollbar {
  width: 2px;
}
.today {
  background-color: rgba(27, 60, 31, 0.8);
}
.calendar-daycell{
  border-bottom: 0 !important;
  padding: 0 !important;
  padding-left: 5px !important;
  width: 15% !important;
  border-right: 1px dashed #888888;
  /*background-color: rgba(14, 19, 14, 0.29);*/
  height: 11vh;
  vertical-align: top !important;
  cursor: pointer;
}
.empty-daycell{
  border-bottom: 0 !important;
  padding: 0 !important;
  padding-left: 5px !important;
  width: 15% !important;
  height: 11vh;
  vertical-align: top !important;

}
.calendar-datacell{
  border-bottom: 0 !important;
  padding: 0 !important;
  vertical-align: top !important;
}
.calendar-shared{
  color: yellow !important;
}